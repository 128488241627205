<template>
  <div class="home">
    <div class="home-img-update">
      <el-text style="font-size: large;color: black;">
        Web Products Photo
      </el-text>
      <el-upload action="#" list-type="picture-card" :auto-upload="false" :on-preview="handlePictureCardPreview"
        style="margin-top: 10px;" v-model:file-list="imgs" :on-change="handleChange" :on-remove="handleRemove">
        <el-icon>
          <Plus />
        </el-icon>
      </el-upload>
      <el-dialog v-model="dialogVisible">
        <el-image :src="dialogImageUrl" alt="Preview Image" style="width: 100%;height: 100%;" />
      </el-dialog>
    </div>
    <div class="home-info-update">
      <el-text style="font-size: large;color: black;">
        Web Modification of Information
      </el-text>
      <div style="margin-top: 10px;display: flex;">
        <el-text style="font-size: medium;color: gray;width: 60px;">Tel:</el-text>
        <el-input style="font-size: medium;color: black;" v-model="tel">
        </el-input>
      </div>
      <div style="margin-top: 10px;display: flex;">
        <el-text style="font-size: medium;color: gray;width: 60px;">Email:</el-text>
        <el-input style="font-size: medium;color: black;" v-model="email"></el-input>
      </div>
      <div style="margin-top: 10px;display: flex;">
        <el-text style="font-size: medium;color: gray;width: 60px;">Adress:</el-text>
        <el-input style="font-size: medium;color: black;" v-model="address" type="textarea" autosize></el-input>
      </div>
    </div>
    <div style="margin-top: 20px;display: flex;align-items: center;justify-content: center;">
      <el-button type="primary" @click="updateWeb">Update Web</el-button>
    </div>
    <el-divider />
    <div class="userInfo" style="margin-top: 20px;">
      <div>
        <el-text style="font-size: large;color: black;">User Info Set</el-text>
      </div>

      <div>
        <div style="margin-top: 20px;">
          UserName
        </div>
        <el-input style="width: 200px;" v-model="userinfo.username"></el-input>
      </div>

      <div>
        <div style="margin-top: 20px;">
          PassWord
        </div>
        <el-input style="width: 200px;" v-model="userinfo.password"></el-input>
      </div>

      <div style="margin-top: 20px;">
        <div style="margin-top: 20px;">
          Code
        </div>
        <el-input style="width: 200px;" v-model="userinfo.code"></el-input><el-button @click="sendEmail">Code</el-button>
      </div>
      <div style="margin-top: 20px;margin-bottom: 20px;">
        <el-button @click="updateInfo">Update Info</el-button>
        <el-button @click="logout">Logout</el-button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home {

  padding-left: 10%;
  padding-right: 10%;

  .home-img-update {

    margin-top: 50px;
  }

  .home-info-update {
    margin-top: 20px;
  }
}
</style>

<script setup>
import { Plus } from '@element-plus/icons-vue'
import { ref, reactive, watchEffect } from 'vue';
import $ from 'jquery'
import { ElMessage } from 'element-plus';
import store from '@/store';

// 退出
const logout = () => {
  store.dispatch("logout");
}

const imgs = ref([])

const userinfo = reactive({
  username: "",
  password: "123456",
  code: "",
  email: ""
})

watchEffect(() => {
  if (store.state.user) {
    userinfo.username = store.state.user.username;
    userinfo.email = store.state.user.email;
  }
})

const tel = ref();
const email = ref();
const address = ref();

const webinfo = () => {
  $.ajax({
    url: "https://hualianrui.com:3001/web/info",
    type: "get",
    success(resp) {
      imgs.value=[];
      for (let img of resp.photos) {
        let newFile = {
          url: img,
          name: img
        }
        imgs.value.push(newFile);
      }
      tel.value = resp.telephone;
      email.value = resp.email;
      address.value = resp.address;
    }
  })
}

const updateInfo = () => {
  $.ajax({
    url: "https://hualianrui.com:3001/user/update",
    type: "post",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwt_token"),
      "Content-Type": "application/json"
    },
    data: JSON.stringify({
      "password": userinfo.password,
      "username": userinfo.username,
      "code": userinfo.code
    }),
    success(resp) {
      if (resp.msg == "success") {
        console.log("修改成功");
      } else {
        console.log(resp);
      }
    },
    error() {
      console.log("修改失败");
    }
  });
}

const sendEmail = () => {
  $.ajax({
    url: "https://hualianrui.com:3001/email",
    type: "get",
    data: {
      email: userinfo.email
    },
    success(resp) {
      if (resp.msg == "操作成功") {
        open(
          "发送成功",
          "success"
        )
      } else {
        open(
          "发送失败",
          "error"
        )
      }
    },
    error() {
      open(
        "发送失败",
        "error"
      )
    }
  });
}

const updateWeb = () => {
  let photos = [];
  for (let img of imgs.value) {
    photos.push(img.url);
  }
  $.ajax({
    url: "https://hualianrui.com:3001/web/set",
    type: "post",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwt_token"),
      "Content-Type": "application/json"
    },
    data: JSON.stringify({
      photos: photos,
      telephone: tel.value,
      email: email.value,
      address: address.value
    }),
    success(resp) {
      if (resp.message == "success") {
        open("修改成功", "success");
        webinfo();
      }
    }
  })
}

webinfo();
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const handlePictureCardPreview = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url;
  dialogVisible.value = true;
};

const handleChange = (file, files) => {
  const formData = new FormData()
  formData.append('file', file.raw)
  // 调用上传图片接口
  $.ajax({
    url: "https://hualianrui.com:3001/upload",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwt_token"),
    },
    type: "post",
    data: formData,
    processData: false,
    contentType: false,
    success(resp) {
      let newFile = {
        url: "https://hualianrui.com:3001/img/" + resp.fileName,
        name: resp.fileName
      }
      files.pop();
      files.push(newFile);
    },
    // 发送失败
    error() {
      open("添加失败", "error");
    }
  })

  imgs.value = files;
}

const handleRemove = (file, files) => {
  imgs.value = files;
}


const open = (now_message, color) => {
  ElMessage({
    message: now_message,
    grouping: true,
    type: color,
  })
}
</script>
