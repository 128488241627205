<template>
    <div class="login">
        <div class="login-card">
            <div class="login-head">
                <el-image :src="logo" style="height: 60px;width:300px ;"></el-image>
            </div>
            <div class="login-input" v-if="!emaillogin">
                <input class="acc" type="text" placeholder="Username" v-model="passwordIn.username">
                <input class="acc" type="password" placeholder="Password" v-model="passwordIn.password">
            </div>
            <div class="login-input-email" v-else>
                <input class="acc" type="text" placeholder="Email" v-model="emailIn.email">
                <div style="display: flex;align-items: center;">
                    <input class="acc" type="text" placeholder="Code" style="width: 200px;" v-model="emailIn.code">
                    <el-button style="width: 100px;margin-top: 20px;" text @click="sendEmail">Send</el-button>
                </div>
            </div>
            <div class="login-email">
                <el-text @click="toSwitch">
                    {{ loginform }}
                </el-text>
            </div>
            <div class="login-button">

                <div class="button">
                    <el-button
                        style="width: 200px;background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%) no-repeat;color: white;height: 40px;"
                        @click="login">Login</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.login {
    background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%) no-repeat;
    height: calc(100vh - 80px);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .login-card {
        background-color: rgba(255, 255, 255, 60%);
        border-radius: 1.5rem;
        box-shadow: 0 0 1rem 0.2rem rgb(0 0 0 / 10%);
        width: 700px;
        height: 400px;

        .login-head {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
        }

        .login-input {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .acc {
                outline: none;
                width: 300px;
                height: 40px;
                margin-top: 20px;
                font-size: large;
                padding: 1rem 0 0 1.6rem;
                border: none;
                border-bottom: 1px solid rgb(144, 129, 241);
                color: rgb(144, 129, 241);
                background-color: rgba(0, 0, 0, 0);
            }
        }

        .login-input-email {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .acc {
                outline: none;
                width: 300px;
                height: 40px;
                margin-top: 20px;
                font-size: large;
                padding: 1rem 0 0 1.6rem;
                border: none;
                border-bottom: 1px solid rgb(144, 129, 241);
                color: rgb(144, 129, 241);
                background-color: rgba(0, 0, 0, 0);
            }
        }

        .login-email {
            width: 100%;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 30%;
        }

        .login-button {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            margin-top: 10px;

            .button {
                width: 100%;
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media (max-width: 992px) {
    .login {
        height: calc(100vh - 60px);
    }
}
</style>

<script setup>
import logo from '@/assets/logo.jpg'
import router from '@/router';
import store from '@/store';
import { ElMessage } from 'element-plus';
import { reactive, ref } from 'vue'
import $ from 'jquery'

const emaillogin = ref(false)

const loginform = ref("Email")

const toSwitch = () => {

    if (emaillogin.value) {
        loginform.value = "Email"
    } else {
        loginform.value = "Password"
    }

    emaillogin.value = !emaillogin.value;

}

const login = () => {
    if (emaillogin.value) {
        emailLogin()
    } else {
        passwordLogin()
    }
}

const passwordIn = reactive({
    username: "",
    password: "",
})

const passwordLogin = () => {
    if (passwordIn.username == null || passwordIn.username == "") {
        open("用户名不能为空", "error");
        return;
    }
    if (passwordIn.password == null || passwordIn.password == "") {
        open("密码不能为空", "error");
        return;
    }

    store.dispatch("loginPassword", {
        username: passwordIn.username,
        password: passwordIn.password,
        success() {
            open("登录成功", "success")
            store.dispatch("getinfo", {
                success() {
                    router.push({ name: 'home' });
                    open("登录成功", "success");
                },
                error(resp) {
                    open(resp.message, "error");
                }
            })
        },
        error() {
            open("登录失败", "error");
        }
    })
}

const emailIn = reactive({
    email: "",
    code: ""
})

const emailLogin = () => {

    if (emailIn.email == null || emailIn.email == "") {
        open("邮箱不能为空", "error");
        return;
    }

    if (emailIn.code == null || emailIn.code == "") {
        open("验证码不能为空", "erorr");
        return;
    }

    store.dispatch("loginEmail", {
        email: emailIn.email,
        code: emailIn.code,
        success() {
            open("登录成功", "success")
            store.dispatch("getinfo", {
                success() {
                    router.push({ name: 'home' });
                    open("登录成功", "success");
                },
                error(resp) {
                    open(resp.message, "error");
                }
            })
        },
        error() {
            open("登录失败", "error");
        }
    })
}

const sendEmail = () => {
    $.ajax({
        url: "https://hualianrui.com:3001/email",
        type: "get",
        data: {
            email: emailIn.email
        },
        success(resp) {
            if (resp.msg == "操作成功") {
                open(
                    "发送成功",
                    "success"
                )
            } else {
                open(
                    "发送失败",
                    "error"
                )
            }
        },
        error() {
            open(
                "发送失败",
                "error"
            )
        }
    });
}

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>