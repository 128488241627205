<template>
    <div class="products">
        <div style="display: flex;justify-content:space-between;margin-top: 20px;align-content:center;">
            <el-text style="margin-left: 20px;font-size: larger;">Products</el-text>
            <el-button style="margin-right: 20px;width: 100px;" type="success" plain @click="addProducts">Add</el-button>
        </div>
        <div style="padding-left: 20px;padding-right: 20px;margin-top: 20px;">
            <el-table :data="products">
                <el-table-column label="image" prop="img">
                    <template #default="products">
                        <el-image :src="products.row.img[0]" lazy style="width: 100px; height: 100px;"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="name" prop="name"></el-table-column>
                <el-table-column fixed="right" label="Operations" width="150">
                    <template #default="products">
                        <el-button type="primary" link size="small"
                            @click="updateProducts(products.row.id)">Revise</el-button>
                        <el-button type="danger" link size="small" @click="delProducts(products.row.id)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="products-page" style="margin-top: 20px;">
                <el-pagination small background layout="prev, pager, next" :total="page.total * page.pageSize"
                    v-model:page-size="page.pageSize" v-model:current-page="page.pageNum" />
            </div>
        </div>

    </div>
</template>

<style lang="less" scoped></style>

<script setup>

import $ from 'jquery';
import { reactive, ref } from 'vue';
import router from '@/router';
import { ElMessage } from 'element-plus';

const page = reactive({
    pageNum: 1,
    pageSize: 8,
    total: 1
})


const products = ref([]);

const productList = () => {
    $.ajax({
        url: `https://hualianrui.com:3001/product/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
        type: "get",
        header: {
            "Content-Type": "application/json"
        },
        success(resp) {
            if (resp.msg == "操作成功") {
                products.value = resp.data.rows;
                page.total = resp.data.total;
            }
        },
        error() {
            open("系统问题", "error");
        }
    })
}

productList();

const delProducts = (id) => {
    $.ajax({
        // id主键，必填
        url: "https://hualianrui.com:3001/product/del/" + id,
        type: "delete",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
            "Content-Type": "application/json"
        },
        success(resp) {
            if (resp.msg == "操作成功") {
                open("删除成功", "success");
                productList();
            } else {
                open("删除失败", "error");
            }
        },
        error() {
            open("删除失败", "error");
        }
    })
}

const updateProducts = (id) => {
    router.push({
        name: "productsUpdate",
        params: {
            id: id
        }
    })
}

const addProducts = () => {
    router.push({ name: 'productsAdd' });
}

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>