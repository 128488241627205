import {
  createStore
} from 'vuex'
import ModuleUser from './user'
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user: ModuleUser
  }
})