import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '@/views/home/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ProductsView from '@/views/products/ProductsView.vue'
import BlogView from '@/views/blog/BlogView.vue'
import ProductsViewAdd from '@/views/products/components/AddProductsView.vue'
import BlogsViewAdd from '@/views/blog/components/AddBlogsView.vue'

import store from '@/store'
const routes = [{
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/login",
    name: 'login',
    component: LoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/products",
    name: 'products',
    component: ProductsView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/add/products",
    name: "productsAdd",
    component: ProductsViewAdd,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/update/products/:id",
    name: "productsUpdate",
    component: ProductsViewAdd,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/add/blogs",
    name: "blogsAdd",
    component: BlogsViewAdd,
    meta: {
      requestAuth: true
    }
  },
  {
    path: "/update/blogs/:id",
    name: "blogsUpdate",
    component: BlogsViewAdd,
    meta: {
      requestAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth && (!store.state.user.is_login)) {
    next({
      name: "login"
    });
  } else {
    next();
  }
})
export default router