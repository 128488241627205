<template>
    <div class="blogs-add">
        <div style="display: flex;margin-left: 20px;margin-top: 20px;">
            <el-text style="width: 120px;">
                Blog Title:
            </el-text>
            <el-input style="width: 180px;" v-model="blog.title">
            </el-input>
        </div>
        <div style="margin-top: 20px;margin-left: 20px;">
            <el-text style="font-size: large;color: black;">
                Blog Photo
            </el-text>
            <el-upload action="#" list-type="picture-card" :auto-upload="false" :on-preview="handlePictureCardPreview"
                style="margin-top: 10px;" v-model:file-list="blog.img" :on-change="handleChange" :on-remove="handleRemove">
                <el-icon>
                    <Plus />
                </el-icon>
            </el-upload>
            <el-dialog v-model="dialogVisible">
                <el-image :src="dialogImageUrl" alt="Preview Image" style="width: 100%;height: 100%;" />
            </el-dialog>
            <el-text style="font-size: medium;color: black;">Note: Only the first image is kept for the blog</el-text>
        </div>
        <div style="margin-top: 20px;height: 500px;padding: 10px;">
            <textarea id="editor" v-model="blog.content"></textarea>
        </div>
        <div
            style="margin-top: 20px;width: 100%;display: flex;align-items: center;justify-content: center;margin-bottom: 20px;">
            <el-button style="margin-right: 20px;width: 200px;height: 50px;" type="success" plain @click="submit">{{
                buttonTxt }}</el-button>
        </div>
    </div>
</template>

<style lang="less" scoped></style>

<script setup>
import { onMounted, reactive, ref, onBeforeUnmount } from 'vue'
import tinymce from 'tinymce'
import $ from 'jquery'
import { Plus } from '@element-plus/icons-vue'
import axios from 'axios';
import { ElMessage } from 'element-plus';
import router from '@/router';

const buttonTxt = ref("Add")

const blog_id = router.currentRoute.value.params.id;
const blog = reactive({
    title: "",
    content: "",
    img: []
})

if (blog_id) {
    buttonTxt.value = "Update";
    $.ajax({
        // 主键id
        url: "https://hualianrui.com:3001/blog/list/" + blog_id,
        type: "get",
        header: {
            "Content-Type": "application/json"
        },
        success(resp) {
            blog.title = resp.data.title;
            blog.content = resp.data.content;
            let newFile = {
                url: resp.data.img,
                name: resp.data.img
            }
            blog.img.push(newFile);

        },
        error() {
            open("请求失败", "error");
        }
    })
}

const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const handlePictureCardPreview = (uploadFile) => {
    dialogImageUrl.value = uploadFile.url;
    dialogVisible.value = true;
};

const handleChange = (file, files) => {
    const formData = new FormData()
    formData.append('file', file.raw)
    // 调用上传图片接口
    $.ajax({
        url: "https://hualianrui.com:3001/upload",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        type: "post",
        data: formData,
        processData: false,
        contentType: false,
        success(resp) {
            let newFile = {
                url: "https://hualianrui.com:3001/img/" + resp.fileName,
                name: resp.fileName
            }
            files.pop();
            files.push(newFile);
        },
        // 发送失败
        error() {
            open("添加失败", "error");
        }
    })

    blog.img = files;
}

const handleRemove = (file, files) => {
    blog.img = files;
}

const submit = () => {
    if (blog_id) {
        update()
    } else {
        add()
    }
}

const add = () => {

    let img = [];
    for (let i of blog.img) {
        img.push(i.url);
    }

    blog.content = tinymce.activeEditor.save();

    axios.post(`https://hualianrui.com:3001/blog/add`,
        JSON.stringify({
            title: blog.title,        // 必填
            content: blog.content, // 必填
            img: img[0]    // 必填
        })
        , {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt_token"),
                "Content-Type": "application/json"
            }
        }).then(() => {
            open("添加成功", "success");
        })

}

const update = () => {
    let img = [];
    for (let i of blog.img) {
        img.push(i.url);
    }
    blog.content = tinymce.activeEditor.save();

    axios.put(`https://hualianrui.com:3001/blog/update`,
        JSON.stringify({
            id: blog_id,
            title: blog.title,        // 必填
            content: blog.content, // 必填
            img: img[0]  // 必填
        })
        , {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt_token"),
                "Content-Type": "application/json"
            }
        }).then(() => {
            open("修改成功", "success");
        })
}
onMounted(() => {
    tinymce.init({
        selector: '#editor',
        menubar: true,
        statusbar: true,
        branding: false,
        plugins: 'advlist autolink link image lists preview', //字符串方式
        toolbar: 'undo redo | styleselect | bold italic | link image',
        min_height: 500,
        images_upload_handler: (blobInfo) =>
            new Promise((resolve) => {
                const maxSize = 2
                const blob = blobInfo.blob()

                // 判断图片大小
                if (blob.size / 1024 / 1024 > maxSize) {
                    return
                }

                // 组装图片信息
                const formData = new FormData()
                formData.append('file', blob, blobInfo.fileName)

                // 调用上传图片接口
                $.ajax({
                    url: "https://hualianrui.com:3001/upload",
                    type: "post",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("jwt_token"),
                    },
                    data: formData,
                    processData: false,
                    contentType: false,
                    success(resp) {
                        resolve("https://hualianrui.com:3001/img/" + resp.fileName);
                    },
                    // 发送失败
                    error() {
                        resolve("请求失败");
                    }
                })
            }),
    });
})

onBeforeUnmount(() => {
    tinymce.activeEditor.destroy();
})

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>