<template>
    <div class="blogs">
        <div style="display: flex;justify-content:space-between;margin-top: 20px;align-content:center;">
            <el-text style="margin-left: 20px;font-size: larger;">Blogs</el-text>
            <el-button style="margin-right: 20px;width: 100px;" type="success" plain @click="addBlogs">Add</el-button>
        </div>
        <div style="padding-left: 20px;padding-right: 20px;margin-top: 20px;">
            <el-table :data="blogs">
                <el-table-column label="image" prop="img">
                    <template #default="blogs">
                        <el-image :src="blogs.row.img" style="width: 100px; height: 100px"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="title" prop="title"></el-table-column>
                <el-table-column fixed="right" label="Operations" width="150">
                    <template #default="blogs">
                        <el-button type="primary" link size="small" @click="updateBlogs(blogs.row.id)">Revise</el-button>
                        <el-button type="danger" link size="small">Delete</el-button>
                    </template>
                </el-table-column>
                <div class="products-page" style="margin-top: 20px;">
                    <el-pagination small background layout="prev, pager, next" :total="page.total * page.pageSize"
                        v-model:page-size="page.pageSize" v-model:current-page="page.pageNum" />
                </div>
            </el-table>
        </div>

    </div>
</template>

<style lang="less" scoped></style>

<script setup>

import router from '@/router';
import { ElMessage } from 'element-plus';
import $ from 'jquery';
import { reactive, ref } from 'vue';
const page = reactive({
    pageNum: 1,
    pageSize: 8,
    total: 1
})

const blogs = ref([])
const blogList = () => {
    $.ajax({
        url: `https://hualianrui.com:3001/blog/list?pageNum=${page.pageNum}&pageSize=${page.pageSize}`,
        type: "get",
        header: {
            "Content-Type": "application/json"
        },
        success(resp) {
            if (resp.msg == "操作成功") {
                blogs.value = resp.data.rows;
                page.total = resp.data.total;
            }
        },
        error() {
            open("系统问题", "error");
        }
    })
}

blogList();

const updateBlogs = (id) => {
    router.push({
        name: "blogsUpdate",
        params: {
            id: id
        }
    })
}

const addBlogs = () => {
    router.push({ name: 'blogsAdd' });
}

const open = (now_message, color) => {
    ElMessage({
        message: now_message,
        grouping: true,
        type: color,
    })
}
</script>