import router from '@/router';
import $ from 'jquery'
export default ({
    state: {
        username: "",
        email: "",
        token: "",
        is_login: false,
    },
    getters: {},
    mutations: {
        updateUser(state, user) {
            state.username = user.username;
            state.email = user.email;
            state.is_login = true;
        },
        updateToken(state, token) {
            state.token = token;
        },
        logout(state) {
            state.username = "";
            state.email = "";
            state.token = "";
            state.is_login = false;
        }
    },
    actions: {
        loginPassword(context, data) {
            $.ajax({
                url: "https://hualianrui.com:3001/login/password",
                type: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify({
                    username: data.username,
                    password: data.password
                }),
                success(resp) {
                    if (resp.message == "success") {
                        localStorage.setItem("jwt_token", resp.token);
                        context.commit("updateToken", resp.token);
                        data.success(resp);
                    } else {
                        data.error(resp);
                    }
                },
                error(resp) {
                    data.error(resp);
                }
            })
        },
        loginEmail(context, data) {
            $.ajax({
                url: "https://hualianrui.com:3001/login/email",
                type: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify({
                    email: data.email,
                    code: data.code
                }),
                success(resp) {
                    if (resp.message == "success") {
                        localStorage.setItem("jwt_token", resp.token);
                        context.commit("updateToken", resp.token);
                        data.success(resp);
                    } else {
                        data.error(resp);
                    }
                },
                error(resp) {
                    data.error(resp);
                }
            })
        },
        getinfo(context, data) {
            $.ajax({
                url: "https://hualianrui.com:3001/user/info",
                type: "post",
                headers: {
                    Authorization: "Bearer " + context.state.token,
                },
                success(resp) {
                    context.commit("updateUser", {
                        ...resp.data,
                        is_login: true,
                    });
                    data.success(resp);
                },
                error(resp) {
                    data.error(resp);
                }
            })
        },
        logout(context) {
            localStorage.removeItem("jwt_token");
            router.push({
                name: 'login'
            });
            context.commit("logout");
        }
    },
    modules: {

    }
})